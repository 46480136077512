.datePicker {
  border: none;

  :global .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
  }

  :global .react-datepicker__day-name {
    color: #bebebe;
  }
}
